import { Component } from 'react';
import { Row, Col, } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Table } from 'react-bootstrap';

import {  getAdminUrl, getAdminHeaders } from 'shared/http';
import { membershipInvoice } from 'shared/settings/apiconfig';

import '../invoice/InvoiceView.scss';
import './InvoiceView.scss'


export default class MembershipInvoice extends Component {
    status = { 'paid': 'fa-check-circle', 'unpaid': 'fa-times-circle' }
    constructor(props) {
        super(props);
        this.state = {
            form: {
                buyerid: this.props.buyerid,
                billingto: {
                    name: '',
                    addressto1: '',
                    addressto2: '',
                    cityState: '',
                    countryZipcode: '',
                    email: '',
                    phonenumber: ''
                },
                billingfrom: {
                    contactname: '',
                    address1: '',
                    address2: '',
                    cityState: '',
                    countryZipcode: '',
                    contactemail: '',
                    contactmobile: ''
                },
                type: '',
                items: [],
                total: '',
          
                tax: '',
                grossamount: '',
                amountdue: '',
                status: '',
                createddate: '',
                timezone: '',
                saleid: '',
                totaltaxonbpamount: '',
                totalInvoiceAmount: '',
                invoicemessage: ''
            },
            totalInvoicePendingAmount: 0,
            totalInvoiceAmountPaid: 0,
            windowWidth: window.innerWidth,
            loading: false,
            invoiceview: 0
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.getInvoiceData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        if (this.props.location?.state?.state?.name === 'Saledashboard') {
        } else {
            window.removeEventListener('popstate', this.onBack);
        }
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    getInvoiceData = () => {
        this.setState({ loading: true });
        let data = {
            "filed": ["vendor","type", "invoicestatus", "buyerid" ],
            "filter": [{ "field": "vendor", "value": "CTBIDS", "op": "=", "join": "AND" },
            { "field": "type", "value": "Membership", "op": "=", "join": "AND" },
            { "field": "invoicestatus", "value": "Paid", "op": "=", "join": "AND" },
            {"field": "buyerid","value": this.state.form.buyerid, "op": "=","join": "AND"},],
            "page": { "size": 1 },
            "sort": [{ "field": "createddate", "direction": "desc" }]
        }

        axios.post(getAdminUrl(membershipInvoice), JSON.stringify(data), getAdminHeaders())
            .then(async response => {
                if (response.data.status === 'success') {
                    let data = response.data.data[0];
                    let invoiceData = JSON.parse(response.data.data[0].invoicedetails);
                    let invoiceMessage = invoiceData.invoicemessage ?  JSON.parse(invoiceData?.invoicemessage) : ''

                        let billingfromCityDelimiter = invoiceData.billingfrom.city && invoiceData.billingfrom.state ? ', ' : '';
                        let billingfromCountryDelimiter = invoiceData.billingfrom.country && invoiceData.billingfrom.zipcode ? ', ' : '';
                        let billingtoCityDelimiter = invoiceData.billingto.city && invoiceData.billingto.state ? ', ' : '';
                        let billingtoCountryDelimiter = invoiceData.billingto.country && invoiceData.billingfrom.zipcode ? ', ' : '';
                        this.setState({
                            form: {
                                ...this.state.form,
                                billingto: {
                                    ...this.state.form.billingto,
                                    name: invoiceData.billingto.lastname + ',' + invoiceData.billingto.firstname,
                                    addressto1: (invoiceData.billingto.address1 ? invoiceData.billingto.address1 : '') + (billingtoCityDelimiter) + (invoiceData.billingto.address2 ? invoiceData.billingto.address2 + "," : ''),
                                    cityState: (invoiceData.billingto.city ? invoiceData.billingto.city : '') + (billingtoCityDelimiter) + (invoiceData.billingto.state ? invoiceData.billingto.state : ''),
                                    countryZipcode: (invoiceData.billingto.country ? invoiceData.billingto.country : '') + (billingtoCountryDelimiter) + (invoiceData.billingto.zipcode ? invoiceData.billingto.zipcode : ''),
                                    email: invoiceData.billingto.contactemail ? invoiceData.billingto.contactemail : invoiceData.billingto.email ? invoiceData.billingto.email : '',
                                    phonenumber: invoiceData.billingto.contactmobile ? invoiceData.billingto.contactmobile : invoiceData.billingto.mobile ? invoiceData.billingto.mobile : '',

                                },
                                billingfrom: {
                                    ...this.state.form.billingfrom,
                                    contactname: invoiceData.billingfrom.contactname ? invoiceData.billingfrom.contactname : '',
                                    address1: invoiceData.billingfrom.address1 ? invoiceData.billingfrom.address1 + ',' + invoiceData.billingfrom.address2 + ',' : '',
                                    cityState: (invoiceData.billingfrom.city ? invoiceData.billingfrom.city : '') + (billingfromCityDelimiter) + (invoiceData.billingfrom.state ? invoiceData.billingfrom.state : ''),
                                    countryZipcode: (invoiceData.billingfrom.country ? invoiceData.billingfrom.country : '') + (billingfromCountryDelimiter) + (invoiceData.billingfrom.zipcode ? invoiceData.billingfrom.zipcode : ''),
                                    contactemail: invoiceData.billingfrom.contactemail ? invoiceData.billingfrom.contactemail : invoiceData.billingfrom.email ? invoiceData.billingfrom.email : '',
                                    contactmobile: invoiceData.billingfrom.contactmobile ? invoiceData.billingfrom.contactmobile : invoiceData.billingfrom.mobile ? invoiceData.billingfrom.mobile : '',

                                },
                                items: invoiceData.items,
                                status: data.invoicestatus,
                                invoicenumber: data.invoicenumber,
                                createddate: data.createddate,
                                totalInvoiceAmount: invoiceData.items[0].grossamount,
                                type: data.type,
                                invoicemessage: invoiceMessage.content.body

                            },
                            loading: false
                        }, () => {
                        });
                    
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }}>
                {this.state.loading ?
                    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100" style={{ padding: "30%" }}>
                        <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                    </div> :
                    <div className="invoiceView buyerContainer container h-90 mt-3">
                        <div className="print" id="invoiceGenration">
                            <div className="d-flex flex-row p-4 border-bottom  flex-wrap">
                                <div className={` ${this.state.windowWidth > 426 ? 'justify-content-left text-left w-50' : 'justify-content-center text-center'} col-md-6 col-xs-12`}>
                                    <div className='d-flex align-items-center media-screen'>
                                        <img src={`${process.env.PUBLIC_URL}/static/logo/premiermember.png`} style={{ marginLeft: "5px" }} alt="logo" height="60px" width="190px" />
                                    </div>
                                </div>
                                <div className={`${this.state.windowWidth > 800 ? 'justify-content-end text-right w-50 mt-3' : 'justify-content-center text-center mt-1'}  col-md-6`}>
                              
                                    {
                                        <div className={` text-secondary col-xs-12`}><div style={{ fontSize: "20px", whiteSpace: 'nowrap', color: '#00263E' }}><b className='text-secondary'>Invoice Type: <span style={{color: '#00263E', fontWeight:'normal'}}>{this.state.form.type?  this.state.form.type : '- - -'} </span></b></div></div>
                                    }
                                </div>
                            </div>
                            <div className={`${this.state.windowWidth > 800 && "d-flex row"} p-4  border-bottom mx-0 pickupStyle`}>
                                <div className={`col col-xs-12 padding  'col-md-4'`}>
                                    <p><b className="text-secondary"> Billing To </b></p>
                                    {Object.keys(this.state.form.billingto).map((key) =>
                                        key === 'name' ? <p><b>{this.state.form.billingto[key]}</b></p> :
                                            key === 'phonenumber' ? this.state.form.billingto[key] ? <p style={{ color: "#1d9aa5" }}>Phone No: {this.state.form.billingto[key]}</p> : '' :
                                                key === 'email' ? this.state.form.billingto[key] ? <p style={{ color: "#1d9aa5" }}>{this.state.form.billingto[key]}</p> : '' : <p style={{ wordBreak: 'break-all' }}>{this.state.form.billingto[key]}</p>
                                    )}
                                </div>

                                <div className={`col col-xs-12 padding  'col-md-6'`}>
                                    <p><b className="text-secondary">{"Billing From"}</b></p>
                                    {Object.keys(this.state.form.billingfrom).map((key) =>
                                        key === 'contactname' ? <p><b>{this.state.form.billingfrom[key]}</b></p> :
                                            key === 'contactmobile' ? this.state.form.billingfrom[key] ? <p style={{ color: "#1d9aa5" }}>Phone No: {this.state.form.billingfrom[key]}</p> : '' :
                                                key === 'contactemail' ? this.state.form.billingfrom[key] ? <p style={{ color: "#1d9aa5" }}>{this.state.form.billingfrom.contactemail} </p> : '' : <p>{this.state.form.billingfrom[key]}</p>
                                    )}
                                </div>
                          
                            </div>
                            <div className={` p-4 border-bottom mx-0`}>
                                <div style={{ fontSize: "20px" }}><p><b className="text-secondary">Invoice Details</b></p></div>
                            </div>
                            <div className={`${this.state.windowWidth > 800 && "d-flex row"} p-4  mx-0 invoiceDate`}>

                                <div className="col col-md-6 col-xs-12 padding"><div >
                                    <p className={` text-secondary col-xs-12`} ><b style={{ fontSize: "20px" }}>Invoice: {this.state.form.invoicenumber} </b>
                                        {<span className={`${this.state.form.status.toLowerCase() === 'paid' ? "text-success" : "text-danger"}`}> 
                                            <i className={`fa ${this.status[this.state.form.status.toLowerCase()]} fa-lg pr-2 pt-1`} aria-hidden="true"></i>{this.state.form.status.toUpperCase()}</span>}</p></div></div>

                                <div className="col col-md-6 col-xs-12 padding" style={{ textAlign: "right" }}>
                                    <p><b className="text-secondary">Invoice Date :- </b><b>{moment.utc(this.state.form.createddate).local().format('MM/DD/YYYY hh:mm A')} {momentTz.tz(momentTz.tz.guess()).zoneAbbr()}</b></p>
                                </div>
                            </div>

                            <div style={{ overflowX: "auto" }} className={` p-4 border-bottom mx-0`}>

                                <Table className="amountfields" responsive>
                                    <thead>
                                        <tr style={{ color: "#9F2241", flexWrap: "nowrap" }}>
                                            <td style={{ whiteSpace: "nowrap", width:'15%' }} >Plan Name</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Coupon Code</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Amount</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Discount Amount</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Tax Amount</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Gross Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.form.items.map((item, index) => (
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap" }}>{item.planname}</td>
                                                    <td  style={{ wordBreak: "break-word" }}>{item.couponcode ? item.couponcode : "- - -"}</td>
                                                    <td  style={{ whiteSpace: "nowrap" }}>{item.amount ? `$ ${parseFloat(item.amount).toFixed(2)}` : '- - -'}</td>
                                                    <td  style={{ whiteSpace: "nowrap" }}>{item.discountamount ? `$ ${parseFloat(item.discountamount).toFixed(2)}` : '- - -'}</td>
                                                    <td  style={{ whiteSpace: "nowrap" }}>{item.taxamount ? `$ ${parseFloat(item.taxamount).toFixed(2)}` : '- - -'}</td>
                                                    <td  style={{ whiteSpace: "nowrap" }}>{item.grossamount ? `$ ${parseFloat(item.grossamount).toFixed(2)}` : '- - -'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                            </div> :
                            <div className="serviceview" style={{ padding: '0 1% 0 1%' }}>

                            </div>

                            <div className="serviceview">
                                <Row className="" >
                                    <Col></Col>
                                    <Col className="text-right text-secondary" ><b>Total Amount</b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "40%" }}>{this.state.form.totalInvoiceAmount ? `$ ${parseFloat(this.state.form.totalInvoiceAmount).toFixed(2)}` : '- - -'}</b></Col>
                                </Row>
                                <Row className="" >
                                    <Col></Col>
                                    <Col className="text-right text-secondary" ><b>Payments Received </b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "40%" }}>{this.state.form.totalInvoiceAmount ? `$ ${parseFloat(this.state.form.totalInvoiceAmount).toFixed(2)}` : '- - -'}</b></Col>
                                </Row>
                                 <Row className="" >
                                    <Col></Col>
                                    <Col className="text-right text-secondary" ><b>Remaining Amount Due </b></Col>
                                    <Col style={{ paddingLeft: this.state.windowWidth > 430 ? "3px" : "7px" }} className="text-end text-secondary"><b style={{ paddingRight: "40%" }}>{ '- - -'}</b></Col>
                                </Row> 
                            </div>
                      
                        </div>
                        <div className={`d-flex flex-column pr-4 invoice-message-wraper ${this.state.windowWidth > 1800 ? 'my-5' : 'my-4'}`} style={{ paddingLeft: "4%", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: this.state?.form?.invoicemessage }}></div>

                    </div>
                }
            </div>
        );
    }
}