import { Component, createRef } from 'react';

// project imports
import Datatable from 'shared/datatable';
import { duplicateAddressUserList } from 'shared/settings/apiconfig';
import { connect } from 'react-redux';
import moment from 'moment';
import * as momentTz from 'moment-timezone'

/**
 * this page is used to list alert duplicate address detail
 * 
 * @param props
 * 
 * @returns html code to render the alert duplicate address detail list
 * 
**/

export class alertDuplicateAddressDetail extends Component {

    config;
    initialSearchState;
    filterSearch;
    id;
    constructor(props) {
        super(props);
        this.dataTable = createRef();
        this.id = '';
        /* initial search state */
        this.initialSearchState = {
            type: { field: 'type', value: '', op: 'LIKE', join: 'AND' },
            registbuyerid: { field: 'registbuyerid', value: '', op: 'LIKE', join: 'AND' },
            registdetails: { field: 'registdetails', value: '', op: 'LIKE', join: 'AND' },
            similarbuyerids: { field: 'similarbuyerids', value: '', op: 'LIKE', join: 'AND' },
            takeaction: { field: 'takeaction', value: '', op: 'LIKE', join: 'AND' }
        }
        this.state = {
            search: this.initialSearchState,
            searchData: false,
            tableData: [],
        }

        this.config = {
            name: 'duplicateAddressDetails',
            url: duplicateAddressUserList,
            isReport: true,
            params: {},
            columns: [ 
                {
                    name: '', property: 'id', columnType: 'actions', actions: ['view'], customHeader: <></>, width:'50px',
                    actionEvents: {
                        view: (row) => {this.props.history.push({ pathname: `/buyer/edit/${row['id']}`})}
                    }
                },
                { name: 'Buyer First Name', property: 'firstName', width: '180px' },
                { name: 'Buyer Last Name', property: 'lastName', width: '180px' },
                { name: 'Buyer Registration Date', property: 'createddate', width: '210px' , columnType: 'custom', customColumn: this.customCreatedDate  },
                { name: 'Buyer Email', property: 'email', width: '210px' },
                { name: 'Buyer Phone Number', property: 'mobile', width: '210px' },
                { name: 'Won Items', property: 'wonItem', width: '110px', columnType: 'custom', customColumn: this.customWonItemCount },
                { name: 'Paid Invoice', property: 'paidInvoice', width: '140px', columnType: 'custom', customColumn: this.customPaidInvoiceCount },
                { name: 'UnPaid Invoice', property: 'unpaidInvoice', width: '150px', columnType: 'custom', customColumn: this.customUnpaidInvoiceCount },
                { name: 'Status', property: 'status', width: '80px', columnType: 'custom', customColumn: this.customStatus },
                { name: 'Suspended', property: 'suspended', width: '120px', columnType: 'custom', customColumn: this.customSuspended }, 
                {
                    name: '', property: 'id', columnType: 'actions', actions: ['accordion'], customHeader: <></>, width: '25px',
                    accordion: this.accordionValue
                }         
            ],
            defaultFilter: [{ "field": "id", "value": this.props.history.location?.state?.buyerids, "op": "IN", "join": "AND" }],
            // field: ["id", "wonItem", "suspended", "email", "status", "address1", "address2", "paidInvoice", "unpaidInvoice"],
            field: ["id", "wonItem","suspended","email", "status",   "address1","address2","  paidInvoice","unpaidInvoice","firstname","lastname","mobile","createdDate"],
            sort: [{ field: 'id', direction: 'asc' }],
            pagination: true,
        };
       
    }

    componentDidMount() {  
        this.enableSearch()
    }

    customPaidInvoiceCount = (row) => {
        return (
            <div className="d-flex flex-row align-items-center"
                style={{ borderRadius: "50%", height: "30px", width: "30px" }}>
                {
                    row['paidInvoice'] ?
                        <span style={{ marginTop: "1px" }}>{row['paidInvoice']}</span> :
                        <span style={{ marginTop: "1px" }}>0</span>
                }
            </div>
        );
    };
    customCreatedDate = (value) => {
        if (value['createddate']) {
            return `${moment.utc(value['createddate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };

    customUnpaidInvoiceCount = (row) => {
        return (
            <div className="d-flex flex-row align-items-center"
                style={{ borderRadius: "50%", height: "30px", width: "30px" }}>
                {
                    row['unpaidInvoice'] ?
                        <span style={{ marginTop: "1px" }}>{row['unpaidInvoice']}</span> :
                        <span style={{ marginTop: "1px" }}>0</span>
                }
            </div>
        );
    };

    customWonItemCount = (row) => {
        return (
            <div className="d-flex flex-row align-items-center"
                style={{ borderRadius: "50%", height: "30px", width: "30px" }}>
                {
                    row['wonItem'] ?
                        <span style={{ marginTop: "1px" }}>{row['wonItem']}</span> :
                        <span style={{ marginTop: "1px" }}>0</span>
                }
            </div>
        );
    };

    customStatus = (values) => {
        if (values['status'])
            return <span>Active</span>;
        else
            return <span>Inactive</span>;
    }

    customSuspended = (values) => {
        if (values['suspended'])
            return <span>Yes</span>;
        else
            return <span>No</span>;
    }

    /* called when accordion clicked */
    accordionValue = (tr) => {
        let row = JSON.stringify(tr)
        row = row.replace(/""/g, '"- - - "')
        row = JSON.parse(row)
        return (
            <div style={{ padding: "1% 2%" }}>
                {row && Object.keys(row).length ?
                    <div className="d-flex" style={{ justifyContent: "space-evenly"}}>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Address 1</div>
                            <div>{row['address1']}</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Address 2</div>
                            <div>{row['address2']}</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">City</div>
                            <div>{row['city']}</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">State</div>
                            <div>{row['state']}</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Country</div>
                            <div>{(row['country'])}</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Zip Code</div>
                            <div>{row['zipcode']}</div>
                        </div>
                    </div>
                    : ''}
            </div>
        );
    }

    enableSearch = () => {
        let search = {};
        search = {
            type: { ...this.state.search.type, value: this.state.search.type.value ? this.state.search.type.value + '*' : '' },
            registbuyerid: { ...this.state.search.registbuyerid, value: this.state.search.registbuyerid.value ?  this.state.search.registbuyerid.value + '*' : '' },
            registdetails: { ...this.state.search.registdetails, value: this.state.search.registdetails.value ? '*' + this.state.search.registdetails.value + '*' : '' },
            similarbuyerids: { ...this.state.search.similarbuyerids, value: this.state.search.similarbuyerids.value ? '*' + this.state.search.similarbuyerids.value + '*' : '' },
            takeaction: { ...this.state.search.takeaction, value: this.state.search.takeaction.value ? '*' + this.state.search.takeaction.value + '*' : '' },
        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }

    navigateToList = () => {
        this.props.history.push({ pathname: this.props.location.state?.prevPath ? this.prevPath.pathname : this.props.location.state.prvpath === 'archive' ? '/alerts/archiveduplicateaddressess' : '/alerts/duplicateaddressess', state:{ datatable : this.props.location.state.tabledata} });
    };

    render() {

        return (
            <div className="buyer-list">
                <div id="backbutton" style={{ padding: "1%", cursor: "pointer", borderBottom: "1px solid #b6b6b6" }}>
                    <span onClick={this.navigateToList} className="clickable">
                        <i className="fa fa-chevron-circle-left fa-lg text-back rounded-circle"></i>
                        <span style={{ paddingLeft: "1em" }}>Back</span>
                    </span>
                </div>
                <div className="buyer-table">
                    <Datatable {...this.props} config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData} />
                </div>
            </div>
        );
    }
}
export default connect(state => state)(alertDuplicateAddressDetail);
