import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Datatable from 'shared/datatable';
import { adminBuyerList, deleteRequestCancel} from 'shared/settings/apiconfig';
import axios from 'axios';
import * as momentTz from 'moment-timezone';
import DatePicker from "react-datepicker";
import { getAdminHeaders,getAdminUrl} from 'shared/http';
import {Modal, Spinner,Form, Row, Col, Button } from 'react-bootstrap';
/**
 * this page is used to list user delete request
 * 
 * @param props
 * 
 * @returns html code to render the user delete request list
 * 
**/

export class alertUserDeleteRequest extends Component {
    config;
    filterSearch;
    constructor(props) {
        super(props);
        this.dataTable = createRef();
        this.id = '';
        this.deleteStatus = {
            'CBBD001': 'Active Bids',
            'CBBD002': 'Unpaid Invoice',
            'CBBD003' : 'Invoice Generation Pending'
          };
        this.initialSearchState = {
            firstname: { field: 'firstname', value: '', op: 'LIKE', join: 'AND' },
            lastname: { field: 'lastname', value: '', op: 'LIKE', join: 'AND' },
            nickname: { field: 'nickname', value: '', op: 'LIKE', join: 'AND' },
            email: { field: 'email', value: '', op: 'LIKE', join: 'AND' },
            mobile: { field: 'mobile', value: '', op: 'LIKE', join: 'AND' },
            suspended: { field: 'suspended', value: '', op: '=', join: 'AND' },
            isDeleted: { field: 'isDeleted', value: 0, op: '=', join: 'AND' },
            starttime: { field: 'deleteRequestTime', value: '', op: '>=', join: 'AND' },
            endtime: { field: 'deleteRequestTime', value: '', op: '<=', join: 'AND' },
            deletestatus: { field: 'deletestatus', value: '',op: '=', join: 'AND'},
        }
        this.state = {
            search: this.initialSearchState,
            searchData: false,
            showCacnelModel: false,
            buyerid: '',
            tableData: []
        }

        if (props.location.state && props.location.state.datatable && props.location.state.datatable.search) {
            let tempSearch = { ...this.initialSearchState };
             const search = [...props.location.state.datatable.search];
             search.forEach(element => {
                let temp = { ...element };
                if (typeof temp['value'] === 'string') {
                    temp['value'] = temp['value'].replace(/(^\*)|(\*$)/g, '');
                }
                if (temp['field'] === "deleteRequestTime" && temp['op'] === '>=') {
                    temp['value'] = new Date(temp['value']);
                    temp['field'] = 'starttime';
                }
                if (temp['field'] === "deleteRequestTime" && temp['op'] === '<=') {
                    temp['value'] = new Date(temp['value']);
                    temp['field'] = 'endtime';
                }
              
                tempSearch[temp['field']] = temp;
            });

            this.state['search'] = tempSearch;
            this.state['searchData'] = true;
         }

        this.config = {
            name: 'alert user delete request',
            url: adminBuyerList,
            params: {},
            columns: [
                {
                    name: '', property: 'id', columnType: 'actions', actions: ['view'], customHeader: <></>, width:'50px',
                    actionEvents: {
                        view: (row) => { this.moveToCustomerProfile(row) }
                    }
                },
                { name: 'Reverse Deletion Request', property: 'cancel', width: '210px', columnType: 'custom', customColumn: this.customCancel, ignoreCSV: true },
                { name: 'Last Review Date', property: 'deleteattemptdate', sort: true, width: '150px', columnType: 'custom', customColumn: this.customAttemptedDate },
                { name: 'Name', property: 'lastname', sort: true, width: '210px', columnType: 'custom', customColumn: this.customName },
                { name: 'Nick Name', property: 'nickname', sort: true, width: '130px' },
                { name: 'Email', property: 'email', sort: true, width: '130px' },
                { name: 'Phone', property: 'mobile', sort: true, width: '130px', sortType: 'num' },
                { name: 'Deletion Request Date', property:'deleteRequestTime', sort: true, width: '130px', columnType: 'custom', customColumn: this.customDate},
                { name: 'Status', property: 'active', width: '130px', columnType: 'custom', customColumn: this.customStatus },
                { name: 'Delete Description', property: 'deleteDescription', width: '150px' },
                { name: 'Delete Reason', property: 'deleteReason', width: '150px' },
                { name: '', property: 'id', columnType: 'actions', actions: [], customHeader: "CSV", width: '170px', actionEvents: {} } 
            ],
            adminUrl: {},
            defaultFilter: [
                { field: 'deleteRequest', value: 1, op: '=', join: 'AND' },     
                { field: 'active', value: 1, op: '=', join: 'AND' },
            ],
            sort: [{ "field": "deleteRequestTime", "direction": "desc" }],
            pagination: true,
            field: ["id", "firstname", "lastname", "email", "nickname", "mobile", "active", "deleteRequestTime", "createddate", "deleteDescription", "deleteReason", "deletestatus", "deleteattemptdate"],
            filename: "alert-user-delete-report",
            ignoreRowBackground: true
        };
        this.filterSearch = {};
    }

    componentDidMount() {  
        if (!this.props?.location?.state?.datatable ) {
            this.enableSearch()
        } 
    }

    moveToCustomerProfile = (row) => {
        this.props.history.push({ pathname: `/buyer/edit/${row['id']}`, state: { prevPath: this.props.match.url, tabledata: this.state.tableData.stateData} });
    }

    customCancel = (value) => {
        return (
            <Button onClick={()=> this.showCancelRequest(value['id'])} className="save-button" >Cancel 
            </Button>
            );
    }
    showCancelRequest = (id) => {
        this.setState({
            showCacnelModel: true,
            buyerid: id
        })
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    
    deleteRequestCancel() {
        this.setState({
            loading: true,
        })
        const data = {buyerid: this.state.buyerid}
        axios.put(getAdminUrl(deleteRequestCancel), JSON.stringify({ "data": data }), getAdminHeaders()).then(response => {
            if (response?.status == 200 || response?.data?.status == "success" ) {
                
                this.setState({
                    loading: false,
                    showReason: false,    
                     searchData: true,
                     showCacnelModel: false
                }, () => {
                     this.dataTable.current.searchData();
                });
            }
        }).catch(err => {
            this.setState({
                loading: false,
                error: err.message
            })
		});
    
    }

    customDate = (value) => {
        if (value['deleteRequestTime']) {
            return `${ moment.utc(value['deleteRequestTime']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    }

    customName = (values) => {
        if (values['lastname'] && values['firstname']) {
            const res = values.lastname + ' ' + values.firstname;
            return res;
        } else if (values['lastname']) {
            const res = values.lastname;
            return res;
        } else if (values['firstname']) {
            const res = values.firstname;
            return res;
        }
    }

    customStatus = (values) => {
        
        let deletestatus = values['deletestatus'] ? JSON.parse(values['deletestatus']) : "";
        const errorcode = deletestatus?.ctbids?.errorCode
        if (values['deletestatus']) {
            const statusValues = errorcode ? errorcode.map(key => this.deleteStatus[key]) : [] 
            const statusWithComma = statusValues ?  statusValues.join(', ') + ' ' : ''; 
            return statusWithComma ? statusWithComma : `- - -`;
        }
        else
            return 'New';
    }
    setDate = (control, date) => {
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [control]: {
                    ...this.state.search[control],
                    value: date
                },
            }
        });

    }
    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event,
        });
    }
    enableSearch = () => {
        let search = {};
        search = {
            firstname: { ...this.state.search.firstname, value: this.state.search.firstname.value ? this.state.search.firstname.value + '*' : '' },
            lastname: { ...this.state.search.lastname, value: this.state.search.lastname.value ?  this.state.search.lastname.value + '*' : '' },
            email: { ...this.state.search.email, value: this.state.search.email.value ? '*' + this.state.search.email.value + '*' : '' },
            mobile: { ...this.state.search.mobile, value: this.state.search.mobile.value ? '*' + this.state.search.mobile.value + '*' : '' },
            nickname: { ...this.state.search.nickname, value: this.state.search.nickname.value ? '*' + this.state.search.nickname.value + '*' : '' },
            starttime: { ...this.state.search.starttime, value: this.state.search.starttime.value ? moment(new Date(this.state.search.starttime.value)).startOf('day').format("YYYY-MM-DD HH:mm:ss") : '' },
            endtime: { ...this.state.search.endtime, value: this.state.search.endtime.value ? moment(new Date(this.state.search.endtime.value)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : '' },
            deletestatus: {...this.state.search.deletestatus, value: this.state.search.deletestatus.value ? this.state.search.deletestatus.value : ''},
            suspended: { ...this.state.search.suspended },
        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }
    closeConfirmModal = () => {
        this.setState({
            showCacnelModel: false
        })
    }
    checkSearch = () => {
        let isenable = false;
        for (let control of Object.keys(this.state.search)) {
            if (this.state.search[control].value) {
                isenable = true;
                break;
            }
        }
        
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
        }
    }
    searchInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
        }, () => {
            // this.searchButton()
            if (event.target.value === '')
                this.checkSearch();
        });
    }
    
    resetState = () => {
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            filterSearch: this.initialSearchState,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    /* called when accordion clicked */
    accordionValue = (tr) => {
        let row = JSON.stringify(tr)
        row = row.replace(/""/g, '"- - - "')
        row = JSON.parse(row)
        return (
            <div style={{ padding: "1% 2%" }}>
                {row && Object.keys(row).length ?
                    <div className="d-flex" style={{ justifyContent: "space-evenly"}}>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Delete Description</div>
                            <div>{row['deleteDescription'] ? row['deleteDescription'] : '- - -' }</div>
                        </div>
                        <div style={{ margin: "0 2%" }}>
                            <div className="text-accordion-header">Delete Reason</div>
                            <div>{row['deleteReason'] ? row['deleteReason'] : '- - -'}</div>
                        </div>
                    </div>
                    : ''}
            </div>
        );
    }

    customAttemptedDate = (value) => {
        if (value['deleteattemptdate']) {
            return `${ moment.utc(value['deleteattemptdate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    }

    render() {

        return (
            <div className="buyer-list">
                <div className="sale-search">
                    <div className="sales-list-search">
                        <Form className="sales-list-font">
                            <div className="sales-search-container">

                                <Row>
                                        <Col>
                                        <label>Bidder email</label>
                                        <Form.Control onKeyDown={this._handleKeyDown} type="text" name="email" value={this.state.search.email.value} onChange={(event) => this.searchInputChange(event)} />
                                    </Col>
                                    <Col>
                                        <label>Delete Date From</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.start.state.open && e.preventDefault()}>
                                                <DatePicker ref={r => this.start = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.starttime.value} selected={this.state.search.starttime.value} onChange={date => { this.setDate('starttime', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#959393" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <label>Delete Date To</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.end.state.open && e.preventDefault()}>
                                                <DatePicker  minDate={this.state.search.starttime.value ? this.state.search.starttime.value : ''} ref={r => this.end = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.endtime.value} selected={this.state.search.endtime.value} onChange={date => { this.setDate('endtime', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#959393" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col className={"px-1"}>
                                        
                                         <label>Status</label>
                                        <Form.Control as="select" name="deletestatus" value={this.state.search.deletestatus.value} onChange={(event) => { this.searchInputChange(event) }}>
                                            <option value='' selected>All</option>
                                            <option value='new'>New</option>
                                            <option value="CBBD001">Active Bids</option>
                                            <option value="CBBD002">Unpaid Invoice</option>
                                            <option value="CBBD003">invoice Generation Pending</option>
                                        </Form.Control> 
                                    </Col> 
                                    
                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="search-button"  onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="buyer-table">
                    <Datatable {...this.props} config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData} />
                </div>
                <div >
                    <Modal show={this.state.showCacnelModel} id="notes-modal" style={{  marginTop: '10%'}}  >
                        <Modal.Body style={{  marginTop: '2%',marginBottom: '2%'}}>
                            <div className="text-center">
                              Do you want to cancel the delete request?
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4 actions">
                            <Button variant="outline-dark btn-white mx-2" onClick={() => this.closeConfirmModal()}>Cancel</Button>
                            <Button variant="secondary mx-2" disabled={this.state.loading} onClick={() => this.deleteRequestCancel()}>Confirm {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null} </Button>
                        </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}
export default connect(state => state)(alertUserDeleteRequest);
