// library imports
import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { alertDuplicateAddress } from 'features/components/base/alerts/alertDuplicateAddress';
import { alertDuplicateAddressDetail } from 'features/components/base/alerts/alertDuplicateAddressDetails';
import { alertUserDeleteRequest } from 'features/components/base/alerts/alertUserDeleteRequest';
import {alertDuplicateAddressArchive} from 'features/components/base/alerts/alertDuplicateAddressArchive';

/**
 * routing for alert components
 * 
 * @param props
 * 
 * @return routing for alert components
 * 
**/

export default class AlertRouter extends Component {

    render() {
        return (
            <Switch>
                <Route exact path={`/alerts`} component={alertDuplicateAddress} />
                <Route exact path={`/alerts/duplicateaddressess`} component={alertDuplicateAddress} />
                <Route exact path={`/alerts/archiveduplicateaddressess`} component={alertDuplicateAddressArchive} />
                <Route exact path={`/alerts/duplicateaddress/detail`} component={alertDuplicateAddressDetail} />
                <Route exact path={`/alerts/userdeleterequest`} component={alertUserDeleteRequest} />

                <Redirect to="/not-found" />
            </Switch>
        );
    }
}


